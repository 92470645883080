<template>
  <div class="bg-white w-full p-3">
    <ExtensionsTable />
  </div>
</template>

<script>
import ExtensionsTable from "@/modules/xpbx/components/numbers/extensions-table/index.vue";

export default {
  name: "Extensions",

  components: {
    ExtensionsTable,
  },
  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>