<template>
  <div class="bg-white w-full h-full">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.internal-numbers')"
        :showButton="false"
      />

      <!-- Extensions table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="extensions"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          v-if="extensions && extensions?.length"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} extensions"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :rowClass="rowClass"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
          :globalFilterFields="['description']"
        >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                </IconField>
                <Export
                  :dt="dt"
                  :tableData="mappedDidNumbers"
                  :columns="extensionHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="extension"
            style="width: 20%"
            :header="$t('xpbx.table-field.extension')"
          />
          <Column
            sortable
            field="user"
            style="width: 20%"
            :header="$t('xpbx.table-field.user')"
          >
            <template #body="slotProps">
              {{ getUser(slotProps?.data?.id) }}
            </template>
          </Column>
          <Column
            sortable
            style="width: 30%"
            field="description"
            :header="$t('xpbx.table-field.description')"
          />

          <Column
            sortable
            field="created_at"
            style="width: 15%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
          <Column
            sortable
            field="updated_at"
            style="width: 15%"
            :header="$t('xpbx.table-field.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.updated_at" /> </template
          ></Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("notify.xpbx.message.confirm_delete", {
              delete: `extension ${
                selectedRecord?.extension ? selectedRecord.extension : ""
              }`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            text
            @click="deleteRecordsDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            text
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create dids -->
      <Dialog
        v-model:visible="numberDialog"
        :style="{ width: '450px' }"
        :header="
          isEdit
            ? $t('xpbx.pages.heading.edit_extension')
            : $t('xpbx.pages.heading.create_extension')
        "
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Extension -->
        <div class="field mb-1 mt-4 relative">
          <label for="extension">{{ $t("xpbx.labels.extension") }}</label>
          <InputText
            id="extension"
            type="text"
            v-model="extension.extension"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !extension.extension }"
          />
          <small class="p-error" v-if="submitted && !!errors?.extension?.[0]">{{
            errors?.extension?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.extensions.extension')" />

        <!-- Description -->
        <div class="field mb-1 mt-4 relative">
          <label for="description">{{ $t("xpbx.labels.description") }}</label>
          <InputText
            id="description"
            type="text"
            v-model="extension.description"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !extension.description }"
          />
          <small
            class="p-error"
            v-if="submitted && !!errors?.description?.[0]"
            >{{ errors?.description?.[0] }}</small
          >
        </div>
        <QueueHint :title="$t('xpbx.hints.extensions.description')" />

        <div class="field mb-1">
          <label for="status">{{ $t("xpbx.labels.status") }}</label>
          <div class="w-40">
            <form-select
              v-model="extension.is_active"
              :options="types"
              name="status"
              id="status"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.extensions.is_active')" />

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            text
            @click="numberDialog = false"
          />
          <Button label="Save" icon="pi pi-check" text @click="create" />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useUsers from "@/modules/xpbx/composables/useUsers";
import useExtensions from "@/modules/xpbx/composables/useExtensions";
import StatusIcon from "@/modules/xpbx/components/UI/status-icon/index.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
import Template from "../../home/modals/template.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import { validateCreateExtensionNumber } from "@/composables/auth/createNumberValidations";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { extensionHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "ExtensionsTable",
  components: {
    PageHeader,
    StatusIcon,
    DateItem,
    DataTable,
    Column,
    Loader,
    Template,
    Button,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    Export,
    QueueHint,
  },

  setup() {
    const users = ref([]);
    const { findUsers } = useUsers();
    const {
      findExtensions,
      deleteExtension,
      extensions,
      extension,
      createExtension,
      updateExtension,
    } = useExtensions();
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const numberDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const resetExtension = () => {
      extension.value = {
        id: "",
        extension: "",
        is_active: 1,
        password: "",
        domain: "",
      };
      submitted.value = false;
    };

    const mappedDidNumbers = computed(() => {
      return extensions.value.map((item) => {
        const user = getUser(item?.id);
        return {
          ...item,
          user,
          is_active: item.is_active == 1 ? "Is active" : "Inactive",
        };
      });
    });

    const openNew = () => {
      isEdit.value = false;
      numberDialog.value = true;
    };

    const closeDialog = (value) => {
      numberDialog.value = value;
    };

    const update = async () => {
      try {
        await updateExtension(extension.value, extension.value.id);
        createExtensionCallback();
      } catch (error) {
        createExtensionCallback();
      }
    };

    const createExtensionCallback = () => {
      isEdit.value = false;
      submitted.value = false;
      numberDialog.value = false;
    };

    const createRecord = async () => {
      submitted.value = true;
      const isValid = validateCreateExtensionNumber(extension.value);

      if (isValid) {
        const formData = {
          // status: extension.value.status,
          is_active: extension.value.is_active,
          extension: extension.value.extension,
          description: extension.value.description,
        };

        try {
          await createExtension(formData);
          createExtensionCallback();
        } catch (error) {
          createExtensionCallback();
        }
      }
    };

    const create = async () => {
      if (isEdit.value) {
        await update();
      } else {
        await createRecord();
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteExtension(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const getUser = (value) => {
      const user = users.value.find((item) => item.extension_id === value);

      return user?.description ? user.description : "";
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        extension.value = event.data;
        isEdit.value = true;
        numberDialog.value = true;
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": +data.is_active === 1,
          "table-inactive": +data.is_active === 0,
          "table-selected": isEdit.value && extension?.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(numberDialog, (value) => {
      if (!value) resetExtension();
    });

    onMounted(async () => {
      checkTableClick();
      const response = await findUsers();
      users.value = response;
      await findExtensions();
    });

    const types = [
      { name: "active", value: 1 },
      { name: "inactive", value: 0 },
    ];

    return {
      dt,
      types,
      extensionHeaders,
      isEdit,
      errors,
      filters,
      submitted,
      extension,
      extensions,
      numberDialog,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      scrollHeight,
      getUser,
      rowClass,
      openNew,
      closeDialog,
      create,
      mappedDidNumbers,
      confirmDeleteSelected,
      deleteSelectedRecords,
      resetExtension,
      onRowSelect,
      onRowUnselect,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>